p {
	font-size: large;
	text-align: left;
}

li {
	font-size: large;
}
/* Landing Page */

.landing-div {
  text-align: center;
  display: grid;
  place-content: center;
  min-height: 100vh;
  width: 100vw;
  background: #062C43;
  padding: 1rem;
}

.landing-content {
  padding: 3rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  border: 1px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  min-width: 70vw;
  min-height: 60vh;
  display: grid;
  place-content: center;
}

.landing-header {
  font-family: cursive;
  font-size: 3rem;
  height: fit-content;
  margin: 0;
  color: white;
  width: fit-content;
  margin: auto;
}

.landing-title {
  font-family:'Segoe UI';
  color: #9ccddc;
}

.landing-nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1rem;
  margin-top: 1rem;
}

.landing-nav-button {
  height: 3rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
}

.landing-nav-button:hover {
  background: #9ccddc;
  color: white;
}

/* Navbar */
.mynav {
	background: #062C43;
	height: 4rem;
}

.nav-text {
	color: #fbf6f3;
	font-size: 1.5rem;
	font-family: Merriweather, sans-serif;
}

.justify-content-end {
	background: #062C43;
	width: 100vw !important;
}

/* About Me */
.aboutme-background {
	background: inherit;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.aboutme-image {
	width: 20rem;
	height: 20rem;
	display: block;
	margin: auto;
  padding-bottom: 2rem;
}

/* Projects */
.myprojects {
	background: url('../images/computer.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	height: 70vh;
	position: relative;
	top: 0;
	left: 0;
}

.tint {
	background: rgba(0, 0, 0, 0.7);
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.proj-header {
	color: white;
	font-weight: bold;
	font-size: 5rem;
}

.projects {
	background: #fbf6f3;
}

.projects-div {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.proj-row {
	min-height: 18rem;
	margin: 0 auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;
}

.proj-img {
	width: 100%;
	height: 100%;
}

.proj-links {
	color: #062C43;
	font-size: large;
}
#proj-links-header {
	font-size: x-large;
}

.proj-col {
	text-align: center;
}

.proj-desc {
	text-align: center;
}

@media (min-width: 990px) {
	.proj-col {
		text-align: left;
	}

	.proj-desc {
		text-align: left;
	}
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

/* resume.html */
.resume {
	padding-top: 3rem;
	padding-bottom: 3rem;
	background: white;
}

.resume-background {
	background: #fbf6f3;
	min-height: 100vh;
}

.resume-file {
  width: 100%;
  height: 80vh;
}

.subheader {
	background: black;
	width: 100%;
	color: white;
	padding-left: 5px;
}

.right-align {
	float: right;
	font-size: medium;
}

.resume-content {
	padding-top: 1.5rem;
}

.resume-divider {
	border-top: 1px solid red;
}

.skills {
	background-color: aqua;
	color: black;
	border: none;
	border-radius: 4px;
	font-size: medium;
}

.padding {
	display: inline-block;
	padding-right: 0.5rem;
	padding-top: 0.5rem;
}

.paragraph-text {
	font-size: medium;
}

/* Contact Me */
.contact {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background: inherit;
}

.contact-header {
	text-align: center;
	font-family: Merriweather, sans-serif;
	font-size: 3rem;
}

.other-contact {
	text-align: center;
	width: 100%;
}

.contact-form {
	text-align: center;
}

.contact-label {
	float: left;
	font-weight: bold;
	font-size: medium;
	font-family: Merriweather, sans-serif;
}

.contact-spinner {
	position: fixed;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
​

/* Individual Projects */
.project-header {
	text-align: center;
	font-size: xx-large;
	font-family: Merriweather, sans-serif;
}

.project-subheader {
	font-size: x-large;
	font-family: Merriweather, sans-serif;
	font-weight: bold;
	text-align: left;
}
#subheader-subheader {
	font-size: large;
}

.walkthrough {
	text-align: center;
}

.walkthrough-img {
	width: 80%;
	height: 60%;
	padding-bottom: 1rem;
}

.project-list {
	text-align: left;
}

.carousel-img {
	width: 80%;
	height: 50vh;
}

.arrow {
	float: left;
}
